h1 {
	background-image: url(background.png);
	width: 578px;
    height: 48px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    padding-top: 5px;
    box-sizing: border-box;
    text-align: center;
}

h1 input {
	font-size: 20px;
	width: calc(100% - 100px);
	margin: 0 50px;
	position: relative;
	top: -5px;
	border-radius: 5px;
	border: none;
	padding: 2px 5px;
	box-sizing: border-box;
}
