.field {
	border-bottom: 1px solid black;
    margin: 0 5px;
    color: black;
    padding-top: 5px;
    text-align: center;
    display: inline-block;
    width: calc(100% - 10px);
    position: relative;
    box-sizing: border-box;
    margin-bottom: 20px;
    top: -20px;
}

.field input {
	width: 100%;
	margin: 0 50px;
	position: absolute;
	top: 0;
	left: -50px;
	border-radius: 5px;
	border: none;
	padding: 2px 5px;
	box-sizing: border-box;
}

.field label {
	color: black;
	width: 100%;
	position: absolute;
	bottom: -20px;
	color: #AAAAAA;
	text-align: center;
	display: block;
}
