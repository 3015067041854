.box {
    padding-top: 17px;
}

.box > .inner {
    border: 1px solid black;
    border-radius: 7px;
    padding: 0.5rem;
    background-color: #F4F4F4;
}

.box > .name {
	position: absolute;
	border: none;
	background: white;
	top: -0;
	left: 20px;
	width: 175px;
	height: 36px;
	padding-top: 8px;
	background: url(nameplate.png);
}

.box > .name input {
	top: 8px;
	left: -20px;
	width: 240px;
}

.box > .name label {
	display: none;
}
