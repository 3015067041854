.characteristic {
	background-image: url(background.png);
	width: 98px;
    height: 104px;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    display: inline-block;
    background-size: cover;
}

.characteristic.force {
	background-image: url(background-force.png);
}

.characteristic input {
	font-size: 42px;
	width: 40px;
	position: absolute;
	text-align: center;
	top: 18px;
	left: 30px;
	border-radius: 5px;
	border: none;
	padding: 2px 0px;
	box-sizing: border-box;
	font-family: inherit;
}

.characteristic label {
	position: relative;
	bottom: -85px;
	display: block;
	text-align: center;
    color: white;
    font-size: 14px;
}

.characteristic .value {
	color: black;
	font-size: 48px;
	width: 100%;
	position: absolute;
	top: 16px;
	left: 0px;
}
