.forcepower {
	position: relative;
	margin-top: 1rem;
	page-break-inside: avoid;
}

.forcepower .remove {
	position: absolute;
	right: -1.5rem;
	top: calc(50% - 1rem);
}

.forcepower .characteristic {
	margin: 20px 12px;
}

.forcepower .text {
	margin-top: 1rem;
}
