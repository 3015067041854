.vehicle {
	position: relative;
	margin-top: 1rem;
	page-break-inside: avoid;
}

.vehicle .characteristic {
	margin: 0 5px;
}

.vehicle .defences {
	position: relative;
	margin-right: 5px;
}

.vehicle .defences .characteristic {
	background: none;
	position: absolute;
}

.vehicle .defences .port input,
.vehicle .defences .starboard input {
	left: 0px;
}

.vehicle .defences .fore {
	left: 5px;
	top: -10px;
	height: 50px;
}

.vehicle .defences .port {
	left: 5px;
	height: 50px;
	width: 45px;
	top: 55px;
}

.vehicle .defences .starboard {
	right: 5px;
	height: 50px;
	width: 45px;
	top: 55px;
}

.vehicle .defences .aft {
	left: 5px;
	top: 120px;
	height: 50px;
}

.vehicle .defences label {
	color: #FFFFFF;
	top: 194px;
	position: relative;
	width: 120px;
	text-align: center;
	display: block;
	font-size: 14px;
}

.vehicle .pool {
	margin-right: 0;
}

.vehicle .characteristic:last-child {
	margin-right: 0;
}

.vehicle .details {
	display: inline-block;
	vertical-align: bottom;
	width: 193px;
	margin-right: 10px;
}

.vehicle .details .field {
	width: calc(100% - 10px);
}

.vehicle .hardpoints {
	margin-top: 2rem;
}

.vehicle .remove {
	position: absolute;
	right: -1.5rem;
	top: calc(50% - 1rem);
}

.vehicle .defences {
	background-image: url(./defence.png);
	width: 120px;
	height: 213px;
	float: left;
	top: 25px;
	position: relative;
}

.vehicle .pool {
	top: 9px;
}
