.valueField {
	background-image: url(background.png);
	width: 90px;
	height: 110px;
	margin: 0 5px;
	background-repeat: no-repeat;
	background-position: center;
	box-sizing: border-box;
	text-align: center;
	position: relative;
	display: inline-block;
	background-size: contain;
	vertical-align: top;
}

.valueField h3 {
	color: white;
	bottom: 2px;
	width: 100%;
	position: absolute;
	font-size: 0.8rem;
}

.valueField input {
	font-size: 42px;
	width: 60px;
	position: absolute;
	text-align: center;
	top: 4px;
	left: 15px;
	border-radius: 5px;
	border: none;
	padding: 2px 0px;
	box-sizing: border-box;
	font-family: inherit;
	border: 2px solid black;
	border-radius: 5px;
}

.valueField .value {
	font-size: 36px;
	text-align: center;
	position: absolute;
	top: 22px;
	width: 100%;
}

.valueField.small .value {
	font-size: 18px;
	top: 33px;
}

.valueField.small input {
	font-size: 24px;
	top: 16px;
}
