.remove {
	width: 2rem;
	height: 2rem;
	position: absolute;
	font-size: 2rem;
	color: black;
	z-index: 10;
	background: white;
	border-radius: 50%;
	cursor: pointer;
}

@media print {
	.remove {
		display: none;
	}
}
