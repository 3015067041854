.pool {
	background-image: url(background.png);
	width: 180px;
    height: 110px;
    margin: 0 5px;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    display: inline-block;
    background-size: cover;
}

.pool h3 {
	color: white;
	margin-top: 11px;
}

.pool input {
	font-size: 42px;
	width: 40px;
	position: absolute;
	text-align: center;
	top: -2px;
	left: 20px;
	border-radius: 5px;
	border: none;
	padding: 2px 0px;
	box-sizing: border-box;
	font-family: inherit;
	border: 2px solid black;
	border-radius: 5px;
}

.pool > label {
	position: relative;
	bottom: -80px;
	display: block;
	text-align: center;
    color: white;
    font-size: 14px;
}

.pool .value {
	font-size: 36px;
	text-align: center;
	position: relative;
}

.pool .left label, .pool .right label {
	color: white;
	position: absolute;
	bottom: -27px;
	font-size: 11px;
	display: block;
}

.pool .left, .pool .right {
	position: absolute;
	top: 40px;
	width: 90px;
	height: 41px;
}

.pool .left {
	left: 0;
}

.pool .right {
	right: 0;
}

.pool .left .value {
	left: 10px;
}

.pool .right .value {
	right: 10px;
}

.pool .left input {
	left: 30px;
}

.pool .left label {
	text-align: right;
	right: 10px;
}

.pool .right label {
	text-align: left;
	left: 10px;
}
