body {
	background: #EFEFEF;
	font-family: 'Share Tech', sans-serif;
	margin-bottom: 6rem;
}

.sheet {
	width: 210mm;
	box-shadow: 5px 5px 15px 0px #BBBBBB;
	box-sizing: border-box;
	padding: 1rem;
	margin: 0 auto;
	background: #FFFFFF;
	position: relative;
	clip-path: polygon(0 0, 150% 0, 100% 200%, 0 200%);
}

.pools {
	margin: 2rem 0 0;
}

.sheet:first-child .pools {
	margin: 2rem 90px 0;
}

.portrait {
	width: 140px;
	height: 190px;
	float: right;
	border: 1px solid black;
	margin: 1.5rem 0 1rem 1.5rem;
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat;
}

.portrait-file {
	display: none;
}

.characteristics {
	text-align: center;
}

.characteristics > .characteristic {
	margin: 0 5px;
}

.sheet > .textfields {
	width: calc(100% - 140px - 1.5rem);
	height: calc(182px + 2.5rem);
	margin-top: 10px;
}

.sheet > .textfields .field {
	top: 0;
	margin-bottom: 20px;
}

.sheet > .textfields > .field {
	width: calc(50% - 21px);
	margin: 0 10px;
}

.sheet > .textfields > .field:first-child {
	margin-left: 0;
}

.sheet > .textfields > .field:last-child {
	margin-right: 0;
}

.sheet > .details {
	margin-top: 1.75rem;
	margin-bottom: 1.5rem;
}

.fluff > .field,
.details > .field {
	margin: 0 10px;
}

.details > .field {
	width: calc(33% - 20px);
}

.fluff {
	margin-top: 20px;
	margin-bottom: 0.5rem;
}

.fluff > .field:nth-child(3n+1) {
	width: calc(75% - 21px);
}

.fluff > .field:nth-child(3n+2) {
	width: calc(25% - 21px);
}

.fluff .remove {
	margin-top: -40px;
	right: 160px;
}

h3 {
	margin-top: 0.75rem;
	font-size: 18px;
	margin-bottom: 0.25rem;
}

.column-2 {
	display: inline-block;
	vertical-align: top;
	width: 50%;
	padding: 0 0.5rem;
	box-sizing: border-box;
}

.column-2:first-child {
	padding-left: 0;
}

.column-2:last-child {
	padding-right: 0;
}

.talents,
.gear {
	width: 100%;
	border-spacing: 0;
	border: 1px solid black;
    border-radius: 7px;
}

.talents tr:nth-child(2n) td,
.gear tr:nth-child(2n) td {
	background-color: #F4F4F4;
}

.talents td,
.gear td {
	height: 1.5rem;
	padding: 0.25rem;
	position: relative;
}

.talents td .field,
.gear td .field {
	margin-bottom: 0;
	top: 0;
	border-bottom: 0;
}

.talents .remove,
.gear .remove {
	position: absolute;
	top: calc(50% - 1rem);
	left: -0.5rem;
}

table th {
	padding-top: 0.25rem;
	background-color: #505050;
	color: #FFFFFF;
}

table tr:last-child td:first-child {
	border-radius: 0 0 0 5px;
}

table tr:last-child td:last-child {
	border-radius: 0 0 5px 0;
}

.controls {
	position: fixed;
	padding: 1rem;
	bottom: 0;
	width: 100%;
	text-align: center;
	background-color: #505050;
	color: #FFFFFF;
	left: 0;
	z-index: 100;
}

.controls > * {
	padding: 0.5rem 1rem;
	margin: 0 0.5rem;
	font-size: 1rem;
	border-radius: 5px;
	border: none;
	background-color: white;
	z-index: 100;
}

@media print {
	body {
		background-color: transparent;
		margin: 0;
	}
	
	.sheet {
		box-shadow: none;
		margin: 0;
		page-break-after: always;
		height: 297mm;
	}
	
	.controls {
		display: none;
	}

	.pools {
		position: absolute;
		bottom: 1rem;	
	}
}
