.skills {
	width: 100%;
	border-spacing: 0;
	border: 1px solid black;
    border-radius: 7px;
    z-index: 5;
}

.skills tr:nth-child(2n) td {
	background-color: #F4F4F4;
}
