.skill span,
.skill strong {
	cursor: pointer
}

.skill td {
	padding: 3px 2px;
}

.skill td:nth-child(1) {
	width: 90px;
	padding-left: 5px;
}

.skill td:nth-child(2) {
	color: #AAAAAA;
}

.skill td:nth-child(3) {
	width: 130px;
}

.skill td:nth-child(4) {
	width: 140px;
}


.skill .pip {
	width: 20px;
	height: 1rem;
	border: 1px solid black;
	border-width: 1px 0;
	border-right: 1px solid #BABABA;
	display: inline-block;
	background-color: white;
}

.skill .pip:first-child {
	border-left: 1px solid black;
	border-radius: 7px 0 0 7px;
}

.skill .pip:last-child {
	border-right: 1px solid black;
	border-radius: 0 7px 7px 0;
}

.skill .pip.checked {
	background-color: black;
	border-right: 1px solid #666666;
}

.skill .dice.green {
	width: 0;
	height: 0;
	border: 8px solid transparent;
	border-bottom-color: green;
	position: relative;
	top: -18px;
	margin: 0 2px;
}

.skill .dice.green:after {
	content: '';
	position: absolute;
	left: -8px;
	top: 26px;
	width: 0;
	height: 0;
	border: 8px solid transparent;
	border-top-color: green;
}

.skill .dice.yellow {
	border-top: 8px solid goldenrod;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	box-sizing:border-box;
	display:inline-block;
	height: 8px;
	width: 15px;
	position: relative;
	margin: 0 2px 0 3px;
	top: 1px;
}

.skill .dice.yellow:before {
	content: "";
	position: absolute;
	height: 0;
	width: 0;
	top: -15px;
	left: -5px;
	border-width: 0 8px 7px;
	border-style: solid;
	border-color: transparent transparent goldenrod;
	box-sizing: border-box;
}

.skill .remove {
	position: absolute;
	right: 0.5rem;
	margin-top: -1rem;
}
