.weapon {
	position: relative;
	margin-top: 1rem;
	page-break-inside: avoid;
}

.weapon .box .inner {
	padding-top: 25px;
}

.weapon .characteristic {
	margin: 0 5px;
	background-image: url(background.png)
}

.weapon .characteristic:nth-child(2) {
	margin-left: 0;
}

.weapon .details {
	display: inline-block;
	vertical-align: bottom;
	width: 316px;
}

.weapon .details .field {
	width: calc(100% - 10px);
}

.weapon .details .two .field {
	width: calc(50% - 10px);
}

.weapon .hardpoints {
	margin-top: 2rem;
}

.weapon .remove {
	position: absolute;
	right: -1.5rem;
	top: calc(50% - 1rem);
}
